/**============================================================
*   [Class] 詳細ページメインスライダー
============================================================*/
export default class PageMainSlider {
  constructor(
    target,
    key,
  )
  {
    this.swiperSlide = target;
    this.swiperKey = key;
    this.swiperSlide.classList.remove('swiper-disabled');
    this.swiperSlide.classList.add('swiper-page-main-'+ this.swiperKey);

    let swiperSlides = this.swiperSlide.getElementsByClassName('swiper-slide');
    let slidable = swiperSlides.length > 2;
    this.swiperSlideItemNum = swiperSlides.length * 3;

    this.initSwiper = () => {
      this.mySwiper = new Swiper(this.swiperSlide, {
        slidesPerView: 1,
        spaceBetween: 40,
        loop: slidable,
        loopAdditionalSlides: this.swiperSlideItemNum,
        loopPreventsSlide: true,
        pagination: {
            el: '.swiper-banner-'+ key + ' .swiper-pagination',
            type: 'bullets',
            clickable: true
        },
        navigation: {
          nextEl: '.swiper-page-main-'+ key + ' .swiper-pagination + .swiper-button-prev + .swiper-button-next',
          prevEl: '.swiper-page-main-'+ key + ' .swiper-pagination + .swiper-button-prev',
        },
        watchOverflow: !slidable,
      });
    };

    this.initSwiper();
  }
}
