//import
import './build/anchor.js';

//vueちょい足しverサンプル
import './build/HeaderNavigation.js';

//vueコンポーネントverサンプル
import { createApp } from 'vue';
import FacilityList from './VueComponents/FacilityList.vue';
window.addEventListener('DOMContentLoaded', () => {
  createApp(FacilityList).mount('#facility-list');
})

/**============================================================
*   [Animation] observerでアニメーション用クラス付与
*   target : .p-animation-observer
============================================================*/
import observerShowObj from './build/observerShowObj';
const observerElem = document.getElementsByClassName('p-animation-observer');
const createObserveObj = () => {
  if (!observerElem.length) return;
  for (let i = 0; i < observerElem.length; i++) {
    new observerShowObj(observerElem[i]);
  }
}
document.addEventListener('DOMContentLoaded', createObserveObj, false);

/**============================================================
*   [共通] アコーディオングループ
*   target : .js-toggle-accordion
============================================================*/
import ToggleAccordion from './build/ToggleAccordion.vue';
const ToggleAccordionElem = document.getElementsByClassName('js-toggle-accordion');
const createToggleAccordionObj = () => {
  if (!ToggleAccordionElem.length) return;
  for (let i = 0; i < ToggleAccordionElem.length; i++) {
    createApp(ToggleAccordion).mount(ToggleAccordionElem[i]);
  }
}
document.addEventListener('DOMContentLoaded', createToggleAccordionObj, false);

/**============================================================
*   [共通] ページ下部スライダー
*   target : .p-banner-slider
============================================================*/
import BannerSlider from './build/BannerSlider';
const BannerSliderElem = document.getElementsByClassName('p-banner-slider');
const createBannerSliderObj = () => {
  if (!BannerSliderElem.length) return;
  for (let i = 0; i < BannerSliderElem.length; i++) {
    new BannerSlider(BannerSliderElem[i], i);
  }
}
document.addEventListener('DOMContentLoaded', createBannerSliderObj, false);

/**============================================================
*   [共通] パネル型記事スライダー
*   target : .p-archive-slider
============================================================*/
import ArchiveSlider from './build/ArchiveSlider';
const ArchiveSliderElem = document.getElementsByClassName('p-archive-slider');
const createArchiveSliderObj = () => {
  if (!ArchiveSliderElem.length) return;
  for (let i = 0; i < ArchiveSliderElem.length; i++) {
    new ArchiveSlider(ArchiveSliderElem[i], i);
  }
}
document.addEventListener('DOMContentLoaded', createArchiveSliderObj, false);

/**============================================================
*   [共通] パネル型記事スライダー
*   target : .p-archive-centered-slider
============================================================*/
import ArchiveCenteredSlider from './build/ArchiveCenteredSlider';
const ArchiveCenteredSliderElem = document.getElementsByClassName('p-archive-centered-slider');
const createArchiveCenteredSliderObj = () => {
  if (!ArchiveCenteredSliderElem.length) return;
  for (let i = 0; i < ArchiveCenteredSliderElem.length; i++) {
    new ArchiveCenteredSlider(ArchiveCenteredSliderElem[i], i);
  }
}
document.addEventListener('DOMContentLoaded', createArchiveCenteredSliderObj, false);

/**============================================================
*   [記事詳細] メインスライダー
*   target : .p-post-main-slider
============================================================*/
import PageMainSlider from './build/PageMainSlider';
const PageMainSliderElem = document.getElementsByClassName('p-post-main-slider');
const createPageMainSliderObj = () => {
  if (!PageMainSliderElem.length) return;
  for (let i = 0; i < PageMainSliderElem.length; i++) {
    new PageMainSlider(PageMainSliderElem[i], i);
  }
}
document.addEventListener('DOMContentLoaded', createPageMainSliderObj, false);

/**============================================================
*   [MicroModal]
============================================================*/
import MicroModal from 'micromodal';
MicroModal.init({
  awaitOpenAnimation: true,
  awaitCloseAnimation: true,
  onShow: ()=>{
    document.documentElement.classList.add('is-modal-opened');
  },
  onClose: ()=>{
    document.documentElement.classList.remove('is-modal-opened');
  }
});

/**============================================================
*   [共通] 高さ揃え
*   targetコンテナ : js-fix-height-box
============================================================*/
import FixHeightBox from './build/FixHeightBox';
const FixHeightBoxElem = document.getElementsByClassName('js-fix-height-box');
const createFixHeightBoxObj = () => {
  if (!FixHeightBoxElem.length) return;
  for (let i = 0; i < FixHeightBoxElem.length; i++) {
    new FixHeightBox(FixHeightBoxElem[i], i);
  }
}
document.addEventListener('DOMContentLoaded', createFixHeightBoxObj, false);

/**============================================================
*   [TOP] ニュースティッカー
*   target : .p-news-ticker
============================================================*/
import NewsTicker from './build/NewsTicker';
const NewsTickerElem = document.querySelectorAll('.p-news-ticker');
const createNewsTickerObj = () => {
  if (!NewsTickerElem.length) return;
  for (let i = 0; i < NewsTickerElem.length; i++) {
    new NewsTicker(NewsTickerElem[i], i);
  }
}
document.addEventListener('DOMContentLoaded', createNewsTickerObj, false);

/**============================================================
*   [TOP] メインスライダー
*   target : .p-top-main__slider
============================================================*/
import TopMainSlider from './build/TopMainSlider';
const TopMainSliderElem = document.getElementsByClassName('p-top-main__slider');
const createTopMainSliderObj = () => {
  if (!TopMainSliderElem.length) return;
  for (let i = 0; i < TopMainSliderElem.length; i++) {
    new TopMainSlider(TopMainSliderElem[i], i);
  }
}
document.addEventListener('DOMContentLoaded', createTopMainSliderObj, false);

/**============================================================
*   [Parallax]
*   target : .js-parallax img
============================================================*/
import { gsap, ScrollTrigger} from 'gsap/all';
gsap.registerPlugin(ScrollTrigger);

const targets = document.querySelectorAll('.js-parallax');
targets.forEach((target) => {
  let mq = gsap.matchMedia();

  mq.add('(max-width: 768px)', () => {
    gsap.fromTo(target.querySelector('img'), {
      y: 0,
    }, {
      y: -30, // y方向-に60px移動させる
      ease: 'none', // イージングなし
      scrollTrigger: {
        trigger: target, // ScrollTriggerの開始位置を計算するために使用される要素
        start: 'top bottom', // 1つ目の値がtriggerで指定した要素の開始位置 2つ目の値が画面の開始位置
        end: 'bottom top', // 1つ目の値がtriggerで指定した要素の終了位置 2つ目の値が画面の終了位置
        scrub: 1, // 要素を1秒遅れで追従させる
      }
    });
  });
  mq.add('(min-width: 768px)', () => {
    gsap.fromTo(target.querySelector('img'), {
      y: 0,
    }, {
      y: -60,
      ease: 'none',
      scrollTrigger: {
        trigger: target,
        start: 'top bottom',
        end: 'bottom top',
        scrub: 1,
      }
    });
  });
});

/**============================================================
*   [AreaClimate] エリアと風土スライダー
*   target : .p-area-climate__slider
============================================================*/
import AreaClimateSlider from './build/AreaClimateSlider';
const AreaClimateSliderElem = document.getElementsByClassName('js-area-climate__slider');
const createAreaClimateSliderObj = () => {
  if (!AreaClimateSliderElem.length) return;
  for (let i = 0; i < AreaClimateSliderElem.length; i++) {
    new AreaClimateSlider(AreaClimateSliderElem[i], i, '.js-area-climate__slider');
  }
}
document.addEventListener('DOMContentLoaded', createAreaClimateSliderObj, false);


/**============================================================
*   [menuCurrent] メニューのis-current処理
*   target : .l-gnav__list
============================================================*/
const menuCurrent = () => {
  // 現在のページの URL を取得
  var currentUrl = window.location.href;

  // 'l-gnav__list__item' クラスを持つ全ての 'a' タグを取得
  var links = document.querySelectorAll('.l-gnav__list .l-gnav__list__item a');

  // 各リンクをループして、現在の URL と比較
  links.forEach(function(link) {
      if (link.href === currentUrl) {
          // 一致する場合は 'is-current' クラスを追加
          link.classList.add('is-current');
      }
  });
};
document.addEventListener('DOMContentLoaded', menuCurrent, false);
