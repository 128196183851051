/**============================================================
*   [Class] パネル型記事スライダー
============================================================*/
export default class ArchiveSlider {
  constructor(
    target,
    key,
  )
  {
    this.swiperSlide = target;
    this.swiperKey = key;
    this.swiperSlide.classList.remove('swiper-disabled');
    this.swiperSlide.classList.add('swiper-archive-'+ this.swiperKey);

    let swiperSlides = this.swiperSlide.getElementsByClassName('swiper-slide');
    let slidable = swiperSlides.length > 3;

    this.initSwiper = () => {
      this.mySwiper = new Swiper(this.swiperSlide, {
        slidesPerView: 1,
        loop: slidable,
        pagination: {
            el: '.swiper-archive-'+ key + ' .swiper-pagination',
            type: 'bullets',
            clickable: true
        },
        navigation: {
          nextEl: '.swiper-archive-'+ key + '+ .swiper-button-prev + .swiper-button-next',
          prevEl: '.swiper-archive-'+ key + '+ .swiper-button-prev',
        },
        watchOverflow: !slidable,
        breakpoints: {
          1100: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          }
        },
      });
    };

    this.initSwiper();

  }
}
