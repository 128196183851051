/**============================================================
*   [Class] AreaClimateSlider：エリアと風土
============================================================*/
export default class AreaClimateSlider {
  constructor(
    target,
    key,
    targetClass
  )
  {
    this.breakSmNum = 768;
    //main slide
    this.swiperSlide = document.querySelectorAll(`${targetClass}`)[key].querySelector('.p-area-climate__slider-main');
    this.swiperSlideItem = this.swiperSlide.querySelectorAll('.swiper-slide');
    this.swiperSlideItemNum = this.swiperSlideItem.length * 2;

    //thumb slide
    this.swiperThumbSlide = document.querySelectorAll(`${targetClass}`)[key].querySelector('.p-area-climate__slider-thumb');
    this.swiperThumbSlideItem = this.swiperThumbSlide.querySelectorAll('.swiper-slide');

    this.swiperKey = key;
    this.swiperSlide.classList.remove('swiper-disabled');
    this.swiperSlide.classList.add('swiper-area-climate-'+ this.swiperKey);
    this.swiperThumbSlide.classList.add('swiper-area-climate-thumb-'+ this.swiperKey);

    this.initSwiper = () => {
      //サムネイルスライド
      this.swiperThumbnail = new Swiper(this.swiperThumbSlide, {
        loop: true,
        slidesPerView: 1,
        freeMode: true,
        watchSlidesProgress: true,//スライダーの動きにスライドを追従させる
        loopAdditionalSlides: this.swiperSlideItemNum,
        loopPreventsSlide: true,
        loopedSlides: 0,
        speed: 2000,
        followFinger: false,
        // grabCursor: false,
        slidesPerGroup: 1,
        loopFillGroupWithBlank: true,
      });

      //メインスライド
      this.mySwiper = new Swiper(this.swiperSlide, {
        slidesPerView: 1,
        loop: true,
        loopAdditionalSlides: this.swiperSlideItemNum,
        loopPreventsSlide: true,
        loopedSlides: 0,
        followFinger: false,
        grabCursor: false,
        speed: 2000,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        observer: true,
        observeParents: true,
        thumbs: {
          swiper: this.swiperThumbnail, //連動したいスライダー（swiperThumbnailを指定する）
        },
        pagination: {
          el: '.swiper-area-climate-'+ this.swiperKey + ' .swiper-pagination',
          type: 'bullets',
          clickable: true
        },
      });
    };

    //4枚の倍になるようにclone
    this.cloneSlide = () => {
      for(let i = this.swiperSlideItem.length / 4; i < 2; i++) {
        Object.keys(this.swiperThumbSlideItem).forEach((item)=>{
          const cloneThumb = this.swiperThumbSlideItem[item].cloneNode(true);
          cloneThumb.classList.add('slide-clone');
          this.swiperThumbSlide.querySelector('.swiper-wrapper').append(cloneThumb);
        })
        Object.keys(this.swiperSlideItem).forEach((item)=>{
          const clone = this.swiperSlideItem[item].cloneNode(true);
          clone.classList.add('slide-clone');
          this.swiperSlide.querySelector('.swiper-wrapper').append(clone);
        })
      }
    }

    //PC, SP切替時にdestroyして枚数操作
    //PC:cloneあり
    //SP:cloneなし
    this.checkSlider = () => {
      if(window.innerWidth > this.breakSmNum) {
        if(this.mySwiper) this.mySwiper.destroy(false,true);
        if(this.swiperThumbnail) this.swiperThumbnail.destroy(false,true);
        //4個以下の場合はcloneして挿入
        if(this.swiperSlideItem.length <= 4) {
          this.cloneSlide();
        }
        this.initSwiper();
      }else {
        if(this.mySwiper) {
          this.mySwiper.destroy(false,true);
          this.swiperThumbnail.destroy(false,true);
          const cloneElem = this.swiperSlide.querySelectorAll('.swiper-area-climate-'+ key + ' .slide-clone');
          const cloneThumbElem = this.swiperThumbSlide.querySelectorAll('.swiper-area-climate-thumb-'+ key + ' .slide-clone');
          cloneElem.forEach(element => {
            element.remove();
          })
          cloneThumbElem.forEach(element => {
            element.remove();
          })
        }
        this.initSwiper();
      }
    }

    const mqlAreaSlider = window.matchMedia('(min-width: ' + this.breakSmNum + 'px)');
    this.checkSlider(mqlAreaSlider);
    mqlAreaSlider.addEventListener('change', this.checkSlider, false);
  }
}
