/**============================================================
*   [Class] バナースライダー
============================================================*/
export default class BannerSlider {
  constructor(
    target,
    key,
  )
  {
    this.swiperSlide = target;
    this.swiperKey = key;
    this.swiperSlide.classList.remove('swiper-disabled');
    this.swiperSlide.classList.add('swiper-banner-'+ this.swiperKey);

    let swiperSlides = this.swiperSlide.getElementsByClassName('swiper-slide');
    let slidable = swiperSlides.length > 4;
    let slidableMd = swiperSlides.length > 3;
    let slidableSp = swiperSlides.length > 1;

    this.initSwiper = () => {
      this.mySwiper = new Swiper(this.swiperSlide, {
        slidesPerView: 1,
        spaceBetween: 10,
        loop: slidableSp,
        centeredSlides: true,
        pagination: {
            el: '.swiper-banner-'+ key + ' .swiper-pagination',
            type: 'bullets',
            clickable: true
        },
        navigation: {
          nextEl: '.swiper-banner-'+ key + '+ .swiper-button-prev + .swiper-button-next',
          prevEl: '.swiper-banner-'+ key + '+ .swiper-button-prev',
        },
        watchOverflow: !slidableSp,
        breakpoints: {
          1100: {
            slidesPerView: 4,
            spaceBetween: 20,
            loop: slidable,
            centeredSlides: false,
            watchOverflow: !slidable,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
            loop: slidableMd,
            centeredSlides: false,
            watchOverflow: !slidableMd,
          }
        },
      });
    };

    this.initSwiper();

  }
}
