/**============================================================
*   [Class] ニュースティッカー
============================================================*/
export default class NewsTicker {
  constructor(
    target,
    key,
  )
  {
    const ticker = target;
    this.animTime = 6000;// アニメーション時間(ms)
    this.speed = 100;// テキストの移動速度(px)
    this.limit = 0;// ブレークポイント(px)
    this.animId;
    this.isRunning = false;
    this.key = key;

    // テキスト移動処理
    const textMove = (elm)=> {
      const move = elm.parentNode.clientWidth - elm.clientWidth;
      if (move < 0) {
        elm.style.transform = 'translateX(' + move + 'px)';//動く量
        const tDuration = Math.abs(move) / this.speed
        elm.style.transitionDuration = (tDuration > this.animTime / 1000 - 1.4) ? this.animTime / 1000 - 1.4 + 's' : tDuration + 's';
      }
    }

    // アニメーション処理
    const tickerAnim = ()=> {
      const items = ticker.querySelectorAll('.p-news-ticker__item');
      const running = ticker.querySelector('.run');
      let idx, link, first, next;
      if (!running) { // 実行中の要素がない場合（初回のみ）
        first = items[0];
        link = first.querySelector('.p-news-ticker__item__inner');
        first.classList.add('fadeInUp', 'run');
        first.style.zIndex = 1;
        setTimeout(textMove, 1000, link); // 第3引数に引数linkを指定。こうしないと即実行されてしまうので注意。
      } else {
        for (let i = 0; i < items.length; i++) {
          if (items[i] == running) {
            idx = i; // 実行中要素のインデックスを取得
            break;
          }
        }
        next = items[(idx + 1) % items.length];
        running.classList.replace('fadeInUp', 'fadeOutUp');
        setTimeout(() => {
          running.classList.remove('fadeOutUp', 'run');
          running.style.zIndex = 0;
          link = running.querySelector('.p-news-ticker__item__inner');
          link.style.transform = 'none';
          next.classList.add('fadeInUp', 'run');
          next.style.zIndex = 1;
          link = next.querySelector('.p-news-ticker__item__inner');
          setTimeout(textMove, 1000, link);
        }, 300);
      }
    }

    const loadTicker = ()=> {
      tickerAnim();
      this.animId = setInterval(tickerAnim, this.animTime);
      this.isRunning = true;
    };

    loadTicker();

    // ウィンドウサイズ変更時
    window.addEventListener('resize', () => {
      const windowWidth = window.innerWidth;
      if (windowWidth <= this.limit) {
        ticker.style.display = 'none';
        clearInterval(this.animId);
        this.isRunning = false;
      } else {
        if (!this.isRunning) {
          ticker.style.display = 'block';
          this.animId = setInterval(tickerAnim, this.animTime);
          this.isRunning = true;
        }
      }
    });
  }
}
