<template>
  <div>
    <div class="l-container">
      <div
        class="p-nav-archive-search"
      >
        <!-- PC時の件数（SP別要素になります） -->
        <div class="p-nav-archive-search__number u-visible--sm">
          <span>該当件数</span>
          <strong>{{ meta.total }}</strong>
          <span>件</span>
        </div>
        <!--- ↑↑↑ PC時の件数 -->
        <div
          v-if="facilityTag(facilityPath)"
          class="p-nav-archive-search__buttons"
        >
          <a
            :href="'/' + facilityPath + '/'"
            class="c-button-square"
            :class="{'is-current': containsFacility}"
          >
            <span
              class="c-icon left-xxs"
              aria-hidden="true"
            >
              <svg class="svg-icon_hotel-dims">
                <use xlink:href="/cms/wp-content/themes/mie-gastronomy/assets/img/shared/sprite.symbol.svg#icon_hotel" />
              </svg>
            </span>
            <span class="text u-font--serif">施設</span>
            <i
              class="c-button-square__arrow material-symbols-outlined u-fz--title-sm"
              aria-hidden="true"
            >chevron_right</i>
          </a>
          <a
            :href="'/' + facilityPath + '/cook/'"
            class="c-button-square"
            :class="{'is-current': containsCook}"
          >
            <span
              class="c-icon left-xxs"
              aria-hidden="true"
            >
              <svg class="svg-icon_cook-dims">
                <use xlink:href="/cms/wp-content/themes/mie-gastronomy/assets/img/shared/sprite.symbol.svg#icon_cook" />
              </svg>
            </span>
            <span class="text u-font--serif">料理人</span>
            <i
              class="c-button-square__arrow material-symbols-outlined u-fz--title-sm"
              aria-hidden="true"
            >chevron_right</i>
          </a>
        </div>
        <!-- ↓↓↓PC時の絞り込みボタン（SP別要素になります）可能であればフィルター1つ以上選択時に「is-active」付与したいです -->
        <div
          v-if="facilityTag(facilityPath)"
          class="p-nav-archive-search__filter u-visible--sm"
        >
          <button
            type="button"
            class="c-button-square-small"
            data-micromodal-trigger="modal-search"
          >
            <span
              class="c-icon left-xxs"
              aria-hidden="true"
            >
              <svg class="svg-icon_refine_search-dims">
                <use xlink:href="/cms/wp-content/themes/mie-gastronomy/assets/img/shared/sprite.symbol.svg#icon_refine_search" />
              </svg>
            </span>
            <span class="text">絞り込み</span>
          </button>
        </div>
      <!-- ↑↑↑ PC時の絞り込みボタン -->
      </div>
    </div>

    <!-- 検索モーダル -->
    <div
      id="modal-search"
      class="p-micro-modal"
      aria-hidden="true"
    >
      <div
        class="p-micro-modal__overlay"
        tabindex="-1"
        data-micromodal-close
      >
        <div
          class="p-micro-modal__container p-nav-type-search-modal__container"
          role="dialog"
          aria-modal="true"
        >
          <button
            type="button"
            class="p-micro-modal__icon p-micro-modal__icon--close p-nav-type-search-modal__close"
            aria-label="閉じる"
            data-micromodal-close
          />
          <div class="p-micro-modal__scroller l-scroll-bar p-nav-type-search-modal__scroller">
            <div class="p-micro-modal__content p-nav-type-search-modal__content">
              <nav class="p-nav-type-search">
                <div class="p-nav-type-search__title">
                  <h2 class="c-title-xs u-weight--bold">
                    宿泊施設/オーベルジュを絞り込む
                  </h2>
                </div>
                <div class="p-nav-type-search__body">
                  <section>
                    <h3 class="c-text-md u-weight--bold">
                      エリア
                    </h3>
                    <div class="u-mt--xxs p-nav-type-search__buttons">
                      <template
                        v-for="item in areaOptions"
                        :key="item"
                      >
                        <button
                          type="button"
                          class="c-button-search"
                          :class="{ 'is-active': isSelected('area', item) }"
                          @click="toggleItem('area', item)"
                        >
                          {{ item }}
                        </button>
                      </template>
                    </div>
                  </section>
                  <section>
                    <h3 class="c-text-md u-weight--bold">
                      時間帯
                    </h3>
                    <div class="u-mt--xxs p-nav-type-search__buttons">
                      <template
                        v-for="item in timeZoneOptions"
                        :key="item"
                      >
                        <button
                          type="button"
                          class="c-button-search"
                          :class="{ 'is-active': isSelected('timeZone', item) }"
                          @click="toggleItem('timeZone', item)"
                        >
                          {{ item }}
                        </button>
                      </template>
                    </div>
                  </section>
                  <section>
                    <h3 class="c-text-md u-weight--bold">
                      料理タイプ
                    </h3>
                    <div class="u-mt--xxs p-nav-type-search__buttons">
                      <template
                        v-for="item in cookingTypeOptions"
                        :key="item"
                      >
                        <button
                          type="button"
                          class="c-button-search"
                          :class="{ 'is-active': isSelected('cookingType', item) }"
                          @click="toggleItem('cookingType', item)"
                        >
                          {{ item }}
                        </button>
                      </template>
                    </div>
                  </section>
                </div>
                <div class="p-nav-type-search__action-buttons">
                  <button
                    type="button"
                    class="c-link-text u-color--gray"
                    @click="resetData()"
                  >
                    <span class="c-text-lg u-weight--bold">リセット</span>
                  </button>
                  <button
                    type="button"
                    class="c-button c-button--gray c-button--small p-nav-type-search__button-search"
                    data-micromodal-close
                    @click="filteredData()"
                  >
                    絞り込み検索
                  </button>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /検索モーダル -->

    <div class="l-section-middle u-bg--white60">
      <div class="l-container">
        <!-- ↓↓↓ SP時の件数、絞り込みボタン -->
        <div class="p-nav-archive-search p-nav-archive-search--sp u-visible--sm-down">
          <div class="p-nav-archive-search__number">
            <span>該当件数</span>
            <strong>{{ meta.total }}</strong>
            <span>件</span>
          </div>
          <div class="p-nav-archive-search__filter">
            <button
              type="button"
              class="c-button-square-small"
              data-micromodal-trigger="modal-search"
            >
              <span
                class="c-icon left-xxs"
                aria-hidden="true"
              >
                <svg class="svg-icon_refine_search-dims">
                  <use xlink:href="/cms/wp-content/themes/mie-gastronomy/assets/img/shared/sprite.symbol.svg#icon_refine_search" />
                </svg>
              </span>
              <span class="text">絞り込み</span>
            </button>
          </div>
        </div>
        <!-- ↑↑↑ SP時の件数、絞り込みボタン -->

        <div
          v-if="dataLoaded && posts.length > 0"
          class="l-grid-row-3--md l-grid-row-2--sm l-grid-gap--xs js-fix-height-box"
          :class="{'p-archive-show': dataLoaded}"
          data-each-num-md="3"
          data-each-num-sm="2"
        >
          <template
            v-for="post in posts"
            :key="post"
          >
            <article class="p-archive-item p-archive-item--hover-contents">
              <a :href="post.url">
                <figure class="p-archive-item__thumb">
                  <img
                    :src="post.image"
                    alt=""
                    width="430"
                    height="306"
                    loading="lazy"
                  >
                  <ul
                    v-if="post.program && post.program !== 'なし'"
                    class="p-list-label p-archive-item__label"
                  >
                    <li><span class="c-icon-label c-icon-label--support">{{ post.program }}</span></li>
                  </ul>
                </figure>
                <div class="p-archive-item__body">
                  <div
                    class="p-archive-item__meta js-fix-height-box__item"
                    data-height-group="1"
                  >
                    <ul class="p-list-type">
                      <li v-if="post.category">{{ post.category }}</li>
                      <li
                        v-for="tag in post.tags"
                        :key="tag"
                      >
                        {{ tag }}
                      </li>
                    </ul>
                  </div>
                  <div
                    class="l-gap--xs p-archive-item__title js-fix-height-box__item"
                    data-height-group="2"
                  >
                    <h2 class="c-title-xs u-font--serif u-weight--bold" v-html="post.title"></h2>
                  </div>
                  <div
                    v-if="post.restaurantName"
                    class="l-gap--xxs p-archive-item__restaurant"
                  >
                    <p class="c-text-sm">{{ post.restaurantName }}</p>
                  </div>
                  <div class="l-gap--xs">
                    <p class="c-text-sm">{{ post.contentHeading.replace(/<br\s*\/?>/gi, '') }}</p>
                  </div>
                  <div class="l-gap--xxs">
                    <p class="c-text-sm u-color--gray">{{ post.area }}</p>
                  </div>
                </div>
                <section class="p-archive-item__hover">
                  <div class="p-archive-item__hover__body">
                    <div class="p-archive-item__hover__scroll-body l-scroll-bar">
                      <h3 class="c-title-xs u-font--serif u-color--white u-weight--bold" v-html="post.contentHeading"></h3>
                      <div class="l-gap--xxs">
                        <p class="c-text-md u-color--white u-weight--bold" v-html="post.contentText"></p>
                      </div>
                    </div>
                    <div class="l-gap--sm u-align--center">
                      <div class="c-button c-button--transparent c-button--small"><span class="text">詳しく見る</span></div>
                    </div>
                  </div>
                </section>
              </a>
            </article>
          </template>
        </div>
        <div
          v-else-if="dataLoaded && posts.length === 0"
          class="u-align--center"
        >
          <p class="c-text-md">
            該当するデータが見つかりませんでした。
          </p>
        </div>
        <div
          v-else
          class="p-archive-loading"
        >
          <div class="p-archive-loader">
            Loading...
          </div>
        </div>

        <nav
          v-show="dataLoaded"
          class="p-nav-pager l-gap--lg"
        >
          <ul>
            <li class="p-nav-pager__prev">
              <button
                aria-label="前へ"
                :disabled="meta.current_page.value === 1"
                @click="prevPage"
              >
                <i
                  class="material-symbols-outlined"
                  aria-hidden="true"
                >chevron_left</i>
              </button>
            </li>
            <template
              v-for="(pageNumber) in pageNumbers"
              :key="pageNumber"
            >
              <li
                :class="{ 'is-current': pageNumber === meta.current_page.value }"
              >
                <button
                  v-if="typeof pageNumber != 'string'"
                  @click="goToPage(pageNumber)"
                >
                  <span>{{ pageNumber }}</span>
                </button>
                <span v-else>{{ pageNumber }}</span>
              </li>
            </template>
            <li class="p-nav-pager__next">
              <button
                aria-label="次へ"
                :disabled="meta.current_page.value === meta.last_page.value || meta.last_page.value === 0"
                @click="nextPage"
              >
                <i
                  class="material-symbols-outlined"
                  aria-hidden="true"
                >chevron_right</i>
              </button>
            </li>
          </ul>
        </nav>
        <div class="l-gap--xl">
          <div class="p-nav-buttons">
            <div
              v-if="facilityTag(facilityPath)"
              class="p-nav-buttons__category"
            >
              <a
                :href="'/' + facilityPath + '/'"
                class="c-button-square"
                :class="{'is-current': containsFacility}"
              >
                <span
                  class="c-icon left-xxs"
                  aria-hidden="true"
                >
                  <svg class="svg-icon_hotel-dims">
                    <use xlink:href="/cms/wp-content/themes/mie-gastronomy/assets/img/shared/sprite.symbol.svg#icon_hotel" />
                  </svg>
                </span>
                <span class="text u-font--serif">施設</span>
                <i
                  class="c-button-square__arrow material-symbols-outlined u-fz--title-sm"
                  aria-hidden="true"
                >chevron_right</i>
              </a>
              <a
                :href="'/' + facilityPath + '/cook/'"
                class="c-button-square"
                :class="{'is-current': containsCook}"
              >
                <span
                  class="c-icon left-xxs"
                  aria-hidden="true"
                >
                  <svg class="svg-icon_cook-dims">
                    <use xlink:href="/cms/wp-content/themes/mie-gastronomy/assets/img/shared/sprite.symbol.svg#icon_cook" />
                  </svg>
                </span>
                <span class="text u-font--serif">料理人</span>
                <i
                  class="c-button-square__arrow material-symbols-outlined u-fz--title-sm"
                  aria-hidden="true"
                >chevron_right</i>
              </a>
            </div>
            <div
              class="p-nav-buttons__page"
              :class="{'u-width--auto': !facilityTag(facilityPath)}"
            >
              <a
                v-if="!facilityTag(facilityPath)"
                href="/hotel/"
                class="c-button"
                :class="{'c-button--300': !facilityTag(facilityPath)}"
              ><span class="text">宿泊施設／オーベルジュを見る</span></a>
              <a
                href="/restaurant/"
                class="c-button"
                :class="{'c-button--300': !facilityTag(facilityPath)}"
              ><span class="text">レストランを見る</span></a>
              <a
                href="/foodee-report/"
                class="c-button"
                :class="{'c-button--300': !facilityTag(facilityPath)}"
              ><span class="text">フーディーレポートを見る</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, onUnmounted } from 'vue';
import axios from 'axios';
import MicroModal from 'micromodal';

// 絞り込みの選択肢
const areaOptions = ref();
const timeZoneOptions = ref();
const cookingTypeOptions = ref();

const originalData = ref();
const data = ref();
const posts = ref();
const dataLoaded = ref(false);

// 絞り込み条件
const filterParams = ref({
  area: [],
  timeZone: [],
  cookingType: [],
});

// 絞りこみ検索
const filteredData = () => {
  let filtered = originalData.value;

  if (filterParams.value.area.length > 0) {
    filtered = filtered.filter(item => filterParams.value.area.includes(item.area));
  }
  if (filterParams.value.timeZone.length > 0) {
    // 選択された全てのタイムゾーンに一致するデータをフィルタリング
    filtered = filtered.filter(item => filterParams.value.timeZone.some(selectedTimeZone => item.timeZone.includes(selectedTimeZone)));
  }
  if (filterParams.value.cookingType.length > 0) {
    // 選択された全ての料理種類に一致するデータをフィルタリング
    filtered = filtered.filter(item => filterParams.value.cookingType.some(selectedCookingType => item.cookingType.includes(selectedCookingType)));
  }
  data.value = filtered;
  meta.current_page.value = 1;
  updatePosts();
};

// 絞り込みリセット
const resetData = () => {
  data.value = originalData.value;
  filterParams.value = {
    area: [],
    timeZone: [],
    cookingType: []
  };
  updatePosts();
}

// 絞り込みトグル
const toggleItem = (filter, item) => {
  const arr = filterParams.value[filter];
  const index = arr.indexOf(item);
  if (index === -1) {
    arr.push(item);
  } else {
    arr.splice(index, 1);
  }
};

// 絞り込みクラス付与
const isSelected = (filter, item) => {
  return filterParams.value[filter].includes(item);
};

// ページネーション
const meta = {
  current_page: ref(1),
  from: ref(null),
  to: ref(null),
  last_page: ref(null),
  per_page: ref(30),
  total: ref(),
};

// 現在のページに合わせて投稿データを更新
const updatePosts = () => {
  meta.from.value = (meta.current_page.value - 1) * meta.per_page.value;
  meta.to.value = meta.from.value + meta.per_page.value;
  posts.value = data.value.slice(meta.from.value, meta.to.value);
  meta.total.value = data.value.length;
  meta.last_page.value = Math.ceil(meta.total.value / meta.per_page.value);
};
// ページ番号のリストを生成
const pageNumbers = computed(() => {
  const currentPage = meta.current_page.value;
  const totalPages = meta.last_page.value;
  let pageRange = [];    // ページネーションの配列
  let pageSizes = 7;     // ページネーションの数
  let centeredPages = 1; // 現在のページの前後
  if(isMobile.value) {
    pageSizes = 5;
    centeredPages = 0;
  }

  let omissionSizes = pageSizes  - 2;  // 省略文字の数
  let lastSizes = totalPages - omissionSizes;

  if( pageSizes < totalPages) {
    if( currentPage + centeredPages <= omissionSizes ){
        for( let i = 1; i <= omissionSizes; i++) {
          pageRange.push(i);
        }
        pageRange.push('…');
        pageRange.push(totalPages);

    } else if(lastSizes < currentPage - centeredPages) {
      pageRange.push(1);
      pageRange.push('…');
      for( let i = lastSizes + 1; i <= totalPages; i++) {
        pageRange.push(i);
      }
    } else {
      pageRange.push(1);
      pageRange.push('…');
      for(let i = currentPage - centeredPages; i < currentPage; i++) {
        pageRange.push(i);
      }
      pageRange.push(currentPage);
      for(let i = currentPage + 1; i <= currentPage + centeredPages; i++) {
        pageRange.push(i);
      }
      pageRange.push('…');
      pageRange.push(totalPages);
    }
  } else {
    for(let i = 1; i <= totalPages; i++) {
      pageRange.push(i);
    }
  }
  return pageRange;
});

const isMobile = ref(window.innerWidth <= 767);

const checkWindowSize = () => {
  isMobile.value = window.innerWidth <= 767;
};

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

// ページ 前へ
const prevPage = () => {
  // 前のページに移動
  if (meta.current_page.value > 1) {
    meta.current_page.value--;
    updatePosts();
    scrollToTop();
  }
};

// ページ 次へ
const nextPage = () => {
  // 次のページに移動
  if (meta.current_page.value < meta.last_page.value) {
    meta.current_page.value++;
    updatePosts();
    scrollToTop();
  }
};

// ページ ナンバーへ
const goToPage = (pageNumber) => {
  meta.current_page.value = pageNumber;
  updatePosts();
  scrollToTop();
};

// API
const params = {};

const currentPath = window.location.pathname;
const pathArray = currentPath.split('/');
const firstPath = pathArray[1];
const secondPath = pathArray[2];
if (firstPath === 'hotel' || firstPath === 'restaurant') {
  params.category = firstPath;
  if (secondPath === 'cook') {
    params.postType = 'cook';
  } else {
    params.postType = 'facility';
  }
}

if (firstPath === 'facility_tag') {
  params.tag = pathArray[2];
  params.postType = ['cook', 'facility'];
}

if (firstPath === 'foodee-report') {
  params.postType = 'foodee-report';
}


const api = axios.create({
  headers: {
    'Content-Type': 'application/json',
  }
});

const apiGet = async () => {
  const response = await api.get('/api/', { params });
  data.value = response.data.posts;
  originalData.value = response.data.posts;
  areaOptions.value = response.data.meta.area;
  timeZoneOptions.value = response.data.meta.timeZone;
  cookingTypeOptions.value = response.data.meta.cookingType;
  meta.total.value = data.value.length;
  meta.last_page.value = Math.ceil(meta.total.value / meta.per_page.value);
};

// 現在のURLを取得
const currentURL = window.location.pathname;
const urlParts = currentURL.split('/');

// 配列内に含まれているかをチェック
const containsCook = urlParts.includes('cook');
const containsFacility =
  !containsCook && urlParts.includes('hotel') ||
  !containsCook && urlParts.includes('restaurant');

const facilityPath = urlParts[1];

const facilityTag = (path) => {
  if(path === 'facility_tag' || path === 'foodee-report') {
    return false;
  } else {
    return true;
  }
}

onMounted(async () => {
  // コンポーネントがマウントされたときにapiGetメソッドを呼び出します
  await apiGet();
  updatePosts();
  dataLoaded.value = true;
  MicroModal.init({
    awaitOpenAnimation: true,
    awaitCloseAnimation: true,
    onShow: ()=>{
      document.documentElement.classList.add('is-modal-opened');
    },
    onClose: ()=>{
      document.documentElement.classList.remove('is-modal-opened');
    }
  });
  window.addEventListener('resize', checkWindowSize);
});

onUnmounted(() => {
  window.removeEventListener('resize', checkWindowSize);
});

</script>
