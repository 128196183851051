/**============================================================
*   [Class] トップページ：メインスライダー
============================================================*/
export default class TopMainSlider {
  constructor(
    target,
    key,
  )
  {
    this.swiperSlide = target;
    this.swiperKey = key;
    this.swiperSlide.classList.remove('swiper-disabled');
    this.swiperSlide.classList.add('swiper-top-main-'+ this.swiperKey);

    let swiperSlides = this.swiperSlide.getElementsByClassName('swiper-slide');
    let slidable = swiperSlides.length > 2;
    this.swiperSlideItemNum = swiperSlides.length * 3;

    this.initSwiper = () => {
      this.mySwiper = new Swiper(this.swiperSlide, {
        direction: 'vertical',
        slidesPerView: 1,
        // spaceBetween: 40,
        loop: slidable,
        loopAdditionalSlides: this.swiperSlideItemNum,
        // loopPreventsSlide: true,
        pagination: {
            el: '.swiper-top-main-'+ key + ' .swiper-pagination',
            type: 'bullets',
            clickable: true
        },
        allowTouchMove: false,
        clickable: true,
        followFinger: false,
        speed: 1600,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
          waitForTransition: false,
        },
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        watchOverflow: !slidable,
      });
    };

    this.initSwiper();
  }
}
